import React from "react";

export const CloudIcon = () => {
  return(
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
  >
    <path
      d="M19.4993 22.5719H17.1836V15C17.1836 14.8625 17.0711 14.75 16.9336 14.75H15.0587C14.9212 14.75 14.8087 14.8625 14.8087 15V22.5719H12.4993C12.2899 22.5719 12.1743 22.8125 12.3024 22.975L15.8024 27.4031C15.8258 27.433 15.8557 27.4572 15.8898 27.4738C15.9239 27.4904 15.9613 27.499 15.9993 27.499C16.0372 27.499 16.0747 27.4904 16.1088 27.4738C16.1429 27.4572 16.1728 27.433 16.1961 27.4031L19.6961 22.975C19.8243 22.8125 19.7086 22.5719 19.4993 22.5719Z"
      fill="#1D0642"
    />
    <path
      d="M25.3563 11.9594C23.925 8.18438 20.2781 5.5 16.0063 5.5C11.7344 5.5 8.0875 8.18125 6.65625 11.9563C3.97813 12.6594 2 15.1 2 18C2 21.4531 4.79687 24.25 8.24687 24.25H9.5C9.6375 24.25 9.75 24.1375 9.75 24V22.125C9.75 21.9875 9.6375 21.875 9.5 21.875H8.24687C7.19375 21.875 6.20312 21.4562 5.46562 20.6969C4.73125 19.9406 4.34063 18.9219 4.375 17.8656C4.40313 17.0406 4.68437 16.2656 5.19375 15.6125C5.71562 14.9469 6.44688 14.4625 7.25938 14.2469L8.44375 13.9375L8.87813 12.7937C9.14688 12.0813 9.52187 11.4156 9.99375 10.8125C10.4596 10.2147 11.0114 9.68922 11.6313 9.25313C12.9156 8.35 14.4281 7.87187 16.0063 7.87187C17.5844 7.87187 19.0969 8.35 20.3813 9.25313C21.0031 9.69063 21.5531 10.2156 22.0187 10.8125C22.4906 11.4156 22.8656 12.0844 23.1344 12.7937L23.5656 13.9344L24.7469 14.2469C26.4406 14.7031 27.625 16.2438 27.625 18C27.625 19.0344 27.2219 20.0094 26.4906 20.7406C26.132 21.1013 25.7054 21.3873 25.2355 21.582C24.7656 21.7768 24.2618 21.8763 23.7531 21.875H22.5C22.3625 21.875 22.25 21.9875 22.25 22.125V24C22.25 24.1375 22.3625 24.25 22.5 24.25H23.7531C27.2031 24.25 30 21.4531 30 18C30 15.1031 28.0281 12.6656 25.3563 11.9594Z"
      fill="#1D0642"
    />
  </svg>
  )
}
 ;
